import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useDataModel, useLazyDataModel } from '@thd-nucleus/data-sources';
import { useStoreId } from '@thd-nucleus/experience-context';
import { useLifeCycleEventBus } from '@thd-olt-functional/utils';
import Helper from './Helper';

import { componentReady } from '../publisher';
import { MediaGalleryDesktop as DesktopMediaGallery } from './subcomponents';
import { MediaHighlight } from './subcomponents/MediaHighlight/MediaHighlight';
import { dataModel } from './dataModel';
import { mediaGalleryDesktopPropTypes, mediaGalleryDesktopDefaultProps } from './MediaGalleryPropTypes';
import { MediaGalleryLoader } from './subcomponents/MediaGalleryLoader/MediaGalleryLoader';
import { useDecisionEngine } from '../hooks/DecisionEngine/useDecisionEngine';

export const MediaGalleryDesktop = ({
  collectionId,
  hideProductImagesLabel,
  highlight,
  highRes,
  hover,
  itemId,
  configId,
  mainImageFetchPriority,
  marqueeRef,
  overrideData,
  showLoader
}) => {
  const { media: overrideMedia, ...overrideDataForOverlay } = overrideData || {};
  const {
    externalMedia, configuredProductLabel
  } = useLifeCycleEventBus('configurator.configurator_media_gallery_event');

  const mediaMapRef = useRef(null);
  const [highResImages, setHighResImages] = useState([]);
  const storeId = useStoreId();
  const [showAvataarAR, setShowAvataarAR] = useState(false);
  const [superSkuHoverId, setSuperSkuHoverId] = useState('');
  const showAvataarDefault = Helper.containAvataarId(itemId);
  useEffect(() => {
    window.LIFE_CYCLE_EVENT_BUS.on('media-gallery.avataar.click', (payload) => {
      setShowAvataarAR(payload?.output?.showAvataar);
    });
    return () => {
      window.LIFE_CYCLE_EVENT_BUS.off('media-gallery.avataar.click');
    };
  }, []);
  useEffect(() => {
    if (hover) {
      window.LIFE_CYCLE_EVENT_BUS.on('supersku.hover', (hoverItemId) => {
        setSuperSkuHoverId(hoverItemId?.output);
      });
    }
  }, [hover]);

  const [superSkuHoveredProductRequest, superSkuHoveredProductResponse] = useLazyDataModel('product', { });

  useEffect(() => {
    if (superSkuHoverId) {
      superSkuHoveredProductRequest({
        variables: {
          itemId: superSkuHoverId,
          configId,
          storeId,
          skipPaintDetails: !configId
        }
      });
    }
  }, [superSkuHoverId]);

  useEffect(() => {
    if (itemId) {
      componentReady({ itemId });
    }
  }, []);

  useEffect(() => {
    if (showAvataarDefault) {
      Helper.loadAvataarScript();
    }
  }, [showAvataarDefault]);

  const productData = useDataModel('product', { variables: {
    itemId,
    configId,
    storeId,
    skipPaintDetails: !configId
  },
  skip: !itemId || overrideData });

  const collectionData = useDataModel('collection', {
    variables: { collectionId, storeId },
    skip: !collectionId || overrideData
  });

  const { product } = productData?.data || {};
  const { collection } = collectionData?.data || {};

  const decisionEngineResponse = useDecisionEngine({ media: product?.media });

  // for swatch Hover
  const mainSuperSkuHover = Helper.getMainSuperSkuHover({
    itemId,
    superSkuHoveredProductResponse,
    collectionData,
    overrideData,
    externalMedia,
    showAvataarDefault,
    superSkuHoverId
  });
  const { displayedMedia, ...apiResults } = Helper.getDisplayedMedia({
    decisionEngineResponse,
    isMobile: false,
    itemId,
    productData,
    collectionId,
    collectionData,
    externalMedia,
    overrideData,
    placeholder: showLoader && <MediaGalleryLoader isMobile={false} />,
    showAvataarDefault
  });
  const { augmentedRealityIndex, zoom } = displayedMedia;

  useEffect(() => {
    // TODO: Hook
    if (highRes && zoom) {
      const checkValidityPromise = [];
      zoom.forEach((zoomedImage) => {
        const imageCheckPromise = Helper.getHighResImages(zoomedImage, axios);
        checkValidityPromise.push(imageCheckPromise);
      });
      Promise.all(checkValidityPromise).then((responses) => {
        const images = responses.filter((resp) => !!resp);
        if (images.length) {
          setHighResImages(images);
        }
      }).catch(() => { });
    }
  }, [itemId, !!zoom]);

  if (apiResults.apiState) {
    return apiResults.returnStatement;
  }

  if (highlight) {
    return <MediaHighlight itemId={itemId} />;
  }

  mediaMapRef.current = displayedMedia;

  if (highResImages) {
    mediaMapRef.current = {
      ...mediaMapRef.current,
      highResolution: highResImages
    };
  }

  return (
    <DesktopMediaGallery
      augmentedRealityIndex={augmentedRealityIndex}
      collection={collection}
      configuredProductLabel={configuredProductLabel}
      hideProductImagesLabel={hideProductImagesLabel}
      mainImageFetchPriority={mainImageFetchPriority}
      mainSuperSkuHover={mainSuperSkuHover}
      marqueeRef={marqueeRef}
      media={mediaMapRef.current}
      onAvataarButtonClick={() => setShowAvataarAR(false)}
      overrideData={overrideDataForOverlay}
      product={product}
      showAvataarAR={showAvataarAR}
      showAvataarDefault={showAvataarDefault}
      showLoadingPlaceholder={decisionEngineResponse?.loading}
      isNonTNTDETest={decisionEngineResponse?.isNonTNTDETest}
      imageType={decisionEngineResponse?.imageType}
      totalThumbnailsToShow={6}
    />
  );
};

MediaGalleryDesktop.displayName = 'MediaGalleryDesktop';

MediaGalleryDesktop.propTypes = mediaGalleryDesktopPropTypes;

MediaGalleryDesktop.defaultProps = mediaGalleryDesktopDefaultProps;

MediaGalleryDesktop.dataModel = dataModel;
import React, {
  useContext,
  useEffect,
  useState,
  useRef
} from 'react';
import { node, object, shape as shapeProp } from 'prop-types';
import { AisleBay } from '@thd-olt-component-react/aisle-bay';
import { AugmentedReality, RoomVo, BathRenovation } from '@thd-olt-component-react/augmented-reality';
import { Breadcrumbs } from '@thd-olt-component-react/breadcrumbs';
import { Buybox } from '@thd-olt-component-react/buybox';
import { PIPCalculator, WallAreaCalculator } from '@thd-olt-component-react/calculator';
import { ConsumerCardMessaging } from '@thd-olt-component-react/consumer-card-messaging';
import {
  Col, Row, Placeholder
} from '@thd-olt-component-react/core-ui';
import { DisclaimerMessage } from '@thd-olt-component-react/disclaimer-message';
import {
  extend, params, string, useDataModel, QueryContext,
  QueryProvider, shape, bool, arrayOf
} from '@thd-nucleus/data-sources';
import { Discontinued } from '@thd-olt-component-react/discontinued';
import { EducationalSummary } from '@thd-olt-component-react/educational-summary';
import { EmtLinks } from '@thd-olt-component-react/emt-links';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { ExperienceContext, useStoreId, useConfigService } from '@thd-nucleus/experience-context';
import { CustomerContext, useThdCustomer } from '@thd-olt-functional/customer-information';
import { FeatureBasedRecommendationsImporter } from '@thd-olt-component-react/feature-based-recommendations';
import { FrequentlyBoughtTogether } from '@thd-olt-component-react/frequently-bought-together';
import { GlobalCustomConfigurator } from '@thd-olt-component-react/global-custom-configurator';
import { Hydrator, declareContexts } from '@thd-olt-component-react/hydrator';
import { MediaGalleryWithHotspotsDesktop } from '@thd-olt-component-react/media-gallery';
import { Metadata, ProductMetadata } from '@thd-olt-component-react/metadata';
import { CalcLink } from '@thd-olt-component-react/moving-calculator';
import { OrderSample } from '@thd-olt-component-react/order-sample';
import { PackagesMiniVisual } from '@thd-olt-component-react/packages-mini';
import { Price, PriceClearanceDataModel } from '@thd-olt-component-react/price';
import { FlooringDrawer } from '@thd-olt-component-react/flooring-picker';
import { ProductConfigurator } from '@thd-olt-component-react/product-configurator';
import { ProductDetails } from '@thd-olt-component-react/product-details';
import { ProductInfoBar } from '@thd-olt-component-react/product-info-bar';
import { ProductOverviewAccordionTitle } from '@thd-olt-component-react/product-overview';
import { Promotion, PromotionUrgencyMessaging } from '@thd-olt-component-react/promotion';
import { PromotionProductsAnchor } from '@thd-olt-component-react/promotion-products';
import { QuestionsAndAnswersAccordionTitle } from '@thd-olt-component-react/questions-and-answers';
import { RatingsAndReviewsAccordionTitle } from '@thd-olt-component-react/ratings-and-reviews';
import { ReturnsMessaging } from '@thd-olt-component-react/returns-messaging';
import { RelatedSearch, PopularVariations } from '@thd-olt-component-react/related-search';
import { useNavigate, useLocation, useParams } from '@thd-olt-component-react/router';
import { SalientListItem, SalientPoints } from '@thd-olt-component-react/salient-points';
import { SpecificationsAccordionTitle } from '@thd-olt-component-react/specifications';
import { SponsoredPipBanner, SponsoredCarousel } from '@thd-olt-component-react/sponsored-content';
import { StickyNav } from '@thd-olt-component-react/sticky-nav';
import { SuperSku } from '@thd-olt-component-react/super-sku';
import { ProductPageEventCountdownTimer } from '@thd-olt-component-react/event-countdown-timer';
import {
  DynamicRecsWrapper,
  PipSemWrapper,
  RecentlyViewedWrapper
} from '@thd-olt-component-react/thd-recs-containers';
import { ThdRecsFbt } from '@thd-olt-component-react/thd-recs-fbt';
import { Sharebelt, ShareBeltPrint, ShareBeltShareButton, ShareFavorite } from '@thd-olt-component-react/thd-sharebelt';
import { useLifeCycleEventBus } from '@thd-olt-functional/utils';
import { PIPRedirector, DynamicComponent, AppContext } from '@thd-nucleus/app-render';
import {
  Button, Link, Accordion, AccordionHeader, AccordionBody, Skeleton, SkeletonContent, SkeletonLine
} from '@one-thd/sui-atomic-components';
import { useTheme } from '@thd-olt-component-react/theme-provider';
import {
  shouldDisplayPipInStock,
  shouldDisplayReferrerRecommendations,
  shouldDisplayVisuallySimilar,
  shouldDisplayPipAlternativeRecs,
  shouldDisplayPipCollections,
  shouldDisplayConfigurator,
  getAnchorSku,
  getSampleAnchorSku,
  validatePipFlip,
  flipPip,
  shouldDisplayIrgaccessories,
  shouldDisplayIrgcollections,
  shouldDisplayBatterySystems,
  isBathCategory
} from './product-utils';
import { HammerWrenchIcon, LeadingIcon } from '../pipflip';
import {
  QuestionsAndAnswersAccordionBody, QuestionsAndAnswersAccordionBodyLoad, QuestionsAndAnswersAccordionBodyModel,
  RatingsAndReviewsAccordionBody, RatingsAndReviewsAccordionBodyLoad, RatingsAndReviewsAccordionBodyModel,
  SpecificationsAccordionBody, SpecificationsAccordionBodyLoad, SpecificationsAccordionBodyModel,
  ProductOverviewAccordionBody, ProductOverviewAccordionBodyLoad, ProductOverviewAccordionBodyModel,
  MagicApronBotPrompt, MagicApronBotPromptLoad
} from './dynamic-imports';
import { PMVAndFBT } from './PMVAndFBT';
import ZoneCard from './zone-card';
import { HYDRATION_STANDARD_SCROLL_BUFFER, RECS_REQUEST_KEY, STICKY_HEADER_HEIGHT } from '../../constants';
import '../../styles/product-page.scss';

const ChildMap = ({ children }) => {
  return <Row><Col nopadding>{children}</Col></Row>;
};

ChildMap.propTypes = {
  children: node.isRequired,
};

export const Product = (props) => {
  declareContexts([QueryContext, ExperienceContext, CustomerContext]);
  const thdCustomer = useThdCustomer();
  const { isCustomerIdentified = false } = useThdCustomer() || {};
  const { cookieUtils } = props;
  const [hasPipFlip, setHasPipFlip] = useState(false);
  const {
    configuredProductLabel,
  } = useLifeCycleEventBus('configurator.configurator_choice_selected_event');
  const productId = useParams()?.itemId;
  const [itemId, setItemId] = useState(productId);
  const ctx = useContext(ExperienceContext);
  const { defaultVariables } = useContext(QueryContext) || {};
  const qctx = useContext(QueryContext);
  const { instance } = useContext(AppContext);
  const thdSeoBotDetected = instance?.thdSEOBotDetection;
  const { channel, isServer, referer } = ctx;
  const viewReferer = shouldDisplayReferrerRecommendations(referer);
  const storeId = useStoreId();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [mounted, setMounted] = useState(false);
  const fsRecsPipCollection = useConfigService('fs:isRecsCollectionEnable');
  const fspipRecsPositionAboveABTest = useConfigService('fs:pipRecsPositionAbove');
  const fspipRecsPositionBelowABTest = useConfigService('fs:pipRecsPositionBelow');
  const fspipRecsPositionDefaultABTest = useConfigService('fs:pipRecsPositionDefault');
  const magicApronQABotEnabled = useConfigService('fs-prop:magic-apron-qa-bot-enabled');
  const stickyNavRef = useRef(null);
  const productOverviewRef = useRef(null);
  const specificationsRef = useRef(null);
  const questionsRef = useRef(null);
  const ratingsRef = useRef(null);
  const pipRecsABTest = fspipRecsPositionAboveABTest || fspipRecsPositionBelowABTest || fspipRecsPositionDefaultABTest;
  const arRef = useRef(null);
  const theme = useTheme(Product);
  const {
    hideConsumerCardMessaging,
    hideReturnsMessaging,
    hideSponsoredCarousel,
    hideShareBeltShareButton,
    hideAddToList
  } = theme.props;

  const handleARClick = () => {
    arRef.current.click();
  };

  const updateStickyHeader = () => {
    const el = document.querySelector('.Header3');
    if (!el) return;
    el.classList.remove('Header3-sticky-desktop');
  };

  useEffect(() => {
    updateStickyHeader();
    setMounted(true);
  }, []);

  const onChange = (data = {}) => {
    const { canonicalUrl } = data;
    if (canonicalUrl) {
      navigate(canonicalUrl, { state: { disableScroll: true } });
    }
  };

  const { data: productData } = useDataModel('clientOnlyProduct', {
    variables: {
      itemId,
      storeId,
      isBrandPricingPolicyCompliant: isCustomerIdentified
    },
    skip: !itemId,
    ssr: false
  });

  const anchorSku = getAnchorSku(productData, itemId);
  const sampleAnchorSku = getSampleAnchorSku(productData);
  const pipFlipValidation = validatePipFlip(productData, hasPipFlip);
  const hasPipFlipCookie = (typeof document !== 'undefined' && cookieUtils)
    ? cookieUtils.fed_ReadCookie('tnt_pipflip')
    : null;
  const triggerPipFlip = () => flipPip(
    configuredProductLabel,
    productData,
    hasPipFlip,
    productId,
    setHasPipFlip,
    itemId,
    anchorSku,
    setItemId
  );

  useEffect(() => {
    if (productId !== itemId && !hasPipFlip) {
      setItemId(productId);
    }
  }, [productId]);

  const isPackage = !!productData?.product?.info?.recommendationFlags?.packages;

  const displayConfigurator = shouldDisplayConfigurator(productData, isServer);

  const hideRatingsIfHDPPSku = true;
  const isHDPPSku = productData?.product?.identifiers?.skuClassification === 'hdpp';

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.trigger('configurator.configurator_omsId_event', itemId);
    LIFE_CYCLE_EVENT_BUS.trigger('configurator.hide_quantity', displayConfigurator);
  }, [productData]);

  const [expanded, setExpanded] = useState({});

  const handleAccordionChange = (accordion, canCloseAccordion = true) => {
    if (!expanded[accordion] || canCloseAccordion) {
      setExpanded((prevState) => ({ ...prevState, [accordion]: !(prevState[accordion]) }));
    }
  };

  const scrollTo = (offset, callback) => {
    const fixedOffset = offset.toFixed();
    const onScroll = () => {
      if (window.pageYOffset.toFixed() === fixedOffset) {
        window.removeEventListener('scroll', onScroll);
        callback();
      }
    };
    window.addEventListener('scroll', onScroll);
    onScroll();
    window.scrollTo({
      top: offset,
      behavior: 'smooth'
    });
  };

  const handleProductDetailsClick = (accordion) => {
    handleAccordionChange(accordion, false);
  };

  const handleViewMoreDetails = () => {
    const offset = productOverviewRef.current.offsetTop - STICKY_HEADER_HEIGHT;
    scrollTo(offset, () => handleAccordionChange('#product-section-overview', false));
  };

  const isPaint = productData?.product?.info?.dotComColorEligible;
  const customExperience = productData?.product?.info?.globalCustomConfigurator?.customExperience;
  const customTitle = productData?.product?.info?.globalCustomConfigurator?.customTitle;
  const caretImg = 'https://assets.thdstatic.com/images/v1/caret-orange.svg';
  const caretHoverImg = 'https://assets.thdstatic.com/images/v1/caret-white.svg';
  const [caretImgSrc, setCaretImgSrc] = useState(caretImg);

  return (
    <>
      <ErrorBoundary name="redirector">
        <PIPRedirector disablePEPRedirect disableCanonicalRedirect disableCMMMCRedirect />
      </ErrorBoundary>
      <ErrorBoundary name="product-metadata">
        <Metadata>
          <ProductMetadata itemId={itemId} hideIfHDPPSku={hideRatingsIfHDPPSku} />
        </Metadata>
      </ErrorBoundary>
      <ErrorBoundary name="pip-content">
        <Row className="isBound">
          <Row>
            <ErrorBoundary name="sticknav">
              <StickyNav
                itemId={itemId}
                ref={stickyNavRef}
                ratingsRef={isHDPPSku ? null : ratingsRef}
                onRatingsClick={() => handleAccordionChange('#product-section-rr', false)}
                hideIfHDPPSku={hideRatingsIfHDPPSku}
              >
                <StickyNav.Links>
                  <StickyNav.Link targetRef={productOverviewRef}>
                    <div
                      className="navlink-pso"
                      onClick={() => handleAccordionChange('#product-section-overview', false)}
                      role="button"
                      tabIndex={0}
                    >Product Details
                    </div>
                  </StickyNav.Link>
                  <StickyNav.Link targetRef={specificationsRef}>
                    <div
                      className="navlink-specs"
                      onClick={() => handleAccordionChange('#product-section-key-feat', false)}
                      role="button"
                      tabIndex={0}
                    >Specifications
                    </div>
                  </StickyNav.Link>
                  <StickyNav.Link targetRef={questionsRef}>
                    <div
                      className="navlink-qa"
                      onClick={() => handleAccordionChange('#product-section-qa', false)}
                      role="button"
                      tabIndex={0}
                    >Questions & Answers
                    </div>
                  </StickyNav.Link>
                  {!isHDPPSku
                    && (
                      <StickyNav.Link targetRef={ratingsRef}>
                        <div
                          className="navlink-rr"
                          onClick={() => handleAccordionChange('#product-section-rr', false)}
                          role="button"
                          tabIndex={0}
                        >Customer Reviews
                        </div>
                      </StickyNav.Link>
                    )}
                </StickyNav.Links>
              </StickyNav>
            </ErrorBoundary>
          </Row>
          <Row className="u--paddingTop u--paddingBottom">
            <ErrorBoundary name="breadcrumb-productInfoBar-grp">
              <Col className="u__visible" sm="7" flatten>
                <ErrorBoundary name="breadcrumbs">
                  <Breadcrumbs
                    itemId={itemId}
                    useStencilDesign
                  />
                </ErrorBoundary>
              </Col>
              <Col sm="5" nopadding>
                <ErrorBoundary name="product-info-bar">
                  <div className="product-info-bar--hide-mobile u__flex-end">
                    <ProductInfoBar itemId={itemId} />
                  </div>
                </ErrorBoundary>
              </Col>
            </ErrorBoundary>
          </Row>
          <section id={fspipRecsPositionAboveABTest ? 'RecsAbove' : 'pipsem'} className="grid">
            {(fspipRecsPositionAboveABTest || (!pipRecsABTest
              && viewReferer)) && (
              <ZoneCard>
                <PipSemWrapper
                  schemaName="pipsem"
                  anchorId={itemId}
                  errorBoundary
                  hideATC
                  withReferrer={viewReferer}
                />
              </ZoneCard>
            )}
          </section>
          <Row>
            <ErrorBoundary name="zone-a">
              <Col nopadding={channel === 'desktop'}>
                <Row>
                  <Col>
                    <Discontinued itemId={itemId} />
                  </Col>
                  <Col sm="7" flatten className="product--sticky">
                    <ZoneCard className="zone-card overflow u__clearfix">
                      <Row>
                        <Col sm="12">
                          <ErrorBoundary name="product-details">
                            <ProductDetails itemId={itemId}>
                              <ProductDetails.Badge itemId={itemId} />
                              <ProductDetails.BrandCollection
                                showBrandHyperLink
                                hideRatingsIfHDPPSku={hideRatingsIfHDPPSku}
                              />
                              <div className="product-details__badge-title--wrapper">
                                <ProductDetails.Title />
                              </div>
                              {!hideAddToList && (
                                <div className="product-details__favorites">
                                  <Sharebelt itemId={itemId}>
                                    <ShareFavorite showCircle showFavoritesCount />
                                  </Sharebelt>
                                </div>
                              )}
                              <ProductDetails.Ratings
                                showBadge
                                target="#product-section-rr"
                                offsetselector="#sticky-nav"
                                qaselector="#product-section-qa"
                                onClick={(event) => handleProductDetailsClick(event)}
                                hideRatingsIfHDPPSku={hideRatingsIfHDPPSku}
                              />
                            </ProductDetails>
                          </ErrorBoundary>
                        </Col>
                      </Row>
                      <Col sm="12">
                        <ErrorBoundary name="media-gallery-desktop">
                          <MediaGalleryWithHotspotsDesktop
                            highRes
                            itemId={itemId}
                            showLoader
                            onMediaClick={handleARClick}
                            mainImageFetchPriority="high"
                          />
                        </ErrorBoundary>
                      </Col>
                      <Col>
                        <ErrorBoundary name="sharebelt">
                          <Sharebelt horizontal itemId={itemId}>
                            { !hideShareBeltShareButton && <ShareBeltShareButton email /> }
                            <ShareBeltPrint />
                          </Sharebelt>
                        </ErrorBoundary>
                      </Col>
                    </ZoneCard>
                    <Col className="zone-card overflow">
                      <ErrorBoundary name="RoomVo">
                        <RoomVo
                          itemId={itemId}
                          queryVariables={{
                            itemId,
                            storeId
                          }}
                        />
                      </ErrorBoundary>
                    </Col>

                    <Col className="zone-card overflow">
                      <ErrorBoundary name="augmented-reality">
                        <AugmentedReality
                          itemId={itemId}
                          instructions
                          forwardedRef={arRef}
                        />
                      </ErrorBoundary>
                    </Col>

                  </Col>
                  <Col sm="5" flatten>
                    <div className="overflow">
                      <Row>
                        <ZoneCard nopadding tag={Col}>
                          <ErrorBoundary name="event-countdown-timer">
                            <ProductPageEventCountdownTimer itemId={itemId} />
                          </ErrorBoundary>
                        </ZoneCard>
                        <Col className="zone-card overflow">
                          <PromotionUrgencyMessaging
                            itemId={itemId}
                          />
                          <ErrorBoundary name="price">
                            <Price
                              channel="desktop"
                              displayEachUom={false}
                              itemId={itemId}
                              large
                              disableRangePricing
                              showProjectPrice
                              clsRemediation={{
                                placeholders: true,
                                preservePlaceholders: true
                              }}
                            />
                          </ErrorBoundary>
                        </Col>
                        <Col className="overflow zone-card promotion__card">
                          <ErrorBoundary name="promotion">
                            <Promotion
                              hideConditionalPromoDescription
                              itemId={itemId}
                            />
                          </ErrorBoundary>
                        </Col>
                        <Col className="zone-card overflow">
                          <ErrorBoundary name="disclaimer-message">
                            <DisclaimerMessage itemId={itemId} />
                          </ErrorBoundary>
                        </Col>
                        {
                          !hideConsumerCardMessaging
                          && (
                            <Col className="zone-card overflow consumer-credit-messaging__card">
                              <ErrorBoundary name="consumer-card-messaging">
                                <ConsumerCardMessaging
                                  itemId={itemId}
                                  clsRemediation={{
                                    placeholders: true,
                                    preservePlaceholders: true
                                  }}
                                />
                              </ErrorBoundary>
                            </Col>
                          )
                        }

                        <Col nopadding className="zone-card overflow">
                          <ErrorBoundary name="flooring-picker">
                            <FlooringDrawer itemId={itemId} />
                          </ErrorBoundary>
                        </Col>

                        <Col className="zone-card overflow">
                          <ErrorBoundary name="promotion-products">
                            <PromotionProductsAnchor itemId={itemId} />
                          </ErrorBoundary>
                        </Col>

                        <Col className="zone-card">
                          <ErrorBoundary name="salient">
                            {sampleAnchorSku && (
                              <div className="sui-m-2">
                                <Button variant="secondary" href={sampleAnchorSku}>Go to Main Product Page</Button>
                              </div>
                            )}
                            <SalientPoints itemId={itemId} max={3}>
                              <SalientListItem>
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <Link underline="always" onClick={handleViewMoreDetails}>
                                  View More Details
                                </Link>
                              </SalientListItem>
                            </SalientPoints>
                          </ErrorBoundary>
                        </Col>

                        <Col className="zone-card overflow">
                          <ErrorBoundary name="aisle-bay">
                            <AisleBay
                              itemId={itemId}
                              clsRemediation={{
                                placeholders: true,
                                preservePlaceholders: false
                              }}
                            />
                          </ErrorBoundary>
                        </Col>

                        {displayConfigurator
                          && (
                            <Col className="zone-card overflow">
                              <ErrorBoundary name="product-configurator">
                                <ProductConfigurator
                                  pipFlip={triggerPipFlip}
                                  itemId={itemId}
                                  hasPipFlip={hasPipFlip}
                                />
                              </ErrorBoundary>
                            </Col>
                          )}
                        {!displayConfigurator
                          && (
                            <Col className="zone-card overflow">
                              <ErrorBoundary name="super-sku">
                                <SuperSku
                                  itemId={productId}
                                  onChange={onChange}
                                  large={isPaint}
                                  centeredCards={isPaint}
                                  disableMediaAndAvailability={isPaint}
                                  containerSizeHref="wall-area-calculator"
                                />
                                {hasPipFlipCookie && pipFlipValidation
                                  && (
                                    <div>
                                      <div className="attribute__label">
                                        <div className="label">
                                          Customize:
                                        </div>
                                      </div>
                                      <button
                                        type="button"
                                        className="super-sku__inline-tile border-radius--medium"
                                        onClick={triggerPipFlip}
                                        style={{ marginTop: 5, alignItems: 'center' }}
                                      >
                                        {HammerWrenchIcon}
                                        {hasPipFlipCookie === 'A' && pipFlipValidation && (
                                          <span style={{ paddingLeft: 10 }}>
                                            Create Your Own
                                          </span>
                                        )}
                                        {hasPipFlipCookie === 'B' && pipFlipValidation && (
                                          <span style={{ paddingLeft: 10 }}>
                                            See Additional Options
                                          </span>
                                        )}
                                      </button>
                                    </div>
                                  )}
                                {pipFlipValidation
                                  && customExperience === 'stock-blinds-pipflip' && !customTitle && (
                                  <div>
                                    <div className="attribute__label">
                                      <div className="label">
                                        Customize:
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="sui-btn sui-btn-secondary sui-btn-secondary-focus
                                      sui-btn-secondary-active sui-btn-secondary-hover
                                      sui-font-bold sui-text-base sui-leading-normal sui-tracking-normal
                                      sui-normal-case sui-line-clamp-unset"
                                      onClick={triggerPipFlip}
                                      style={{ marginTop: 5, alignItems: 'center' }}
                                      onMouseEnter={() => setCaretImgSrc(caretHoverImg)}
                                      onMouseLeave={() => setCaretImgSrc(caretImg)}
                                    >
                                      {LeadingIcon}
                                      <span style={{ paddingLeft: 10, paddingRight: 10 }}>
                                        See More Custom Sizes
                                      </span>
                                      <img
                                        src={caretImgSrc}
                                        alt="caret"
                                        height="16"
                                        width="16"
                                        loading="lazy"
                                        style={{ height: '16px', width: '16px' }}
                                      />
                                    </button>
                                  </div>
                                )}
                              </ErrorBoundary>
                            </Col>
                          )}
                        {productData?.product?.info?.globalCustomConfigurator?.customPosition
                          && productData.product.info.globalCustomConfigurator.customPosition === 'above'
                          && (
                            <Col className="zone-card overflow">
                              <ErrorBoundary name="gcc">
                                <GlobalCustomConfigurator itemId={itemId} />
                              </ErrorBoundary>
                            </Col>
                          )}
                        <Col className="zone-card">
                          <ErrorBoundary name="order-sample">
                            <OrderSample itemId={itemId}>
                              Need a closer look?
                            </OrderSample>
                          </ErrorBoundary>
                        </Col>
                        <Col flatten>
                          <ErrorBoundary name="buybox">
                            <Buybox
                              itemId={itemId}
                              hideProReferral={thdCustomer?.isExchangeCustomer}
                              renderCalculator={(buyboxItemId, onCalculate) => {
                                return (
                                  <>
                                    <WallAreaCalculator
                                      itemId={buyboxItemId}
                                    />
                                    <PIPCalculator
                                      itemId={buyboxItemId}
                                      onCalculate={onCalculate}
                                    />
                                  </>
                                );
                              }}
                              sticky
                              showCards
                            />
                          </ErrorBoundary>
                        </Col>
                        {
                          !hideReturnsMessaging
                          && (
                            <ErrorBoundary
                              className="zone-card overflow"
                              name="returns-messaging"
                            >
                              <ReturnsMessaging itemId={itemId} displayTimeframe />
                            </ErrorBoundary>
                          )
                        }
                        <Col className="zone-card sui-p-3">
                          <ErrorBoundary name="moving-calculator">
                            <CalcLink itemId={itemId} />
                          </ErrorBoundary>
                        </Col>

                        {(!productData?.product?.info?.globalCustomConfigurator?.customPosition
                          || productData.product.info.globalCustomConfigurator.customPosition === 'below')
                          && (
                            <Col className="zone-card overflow">
                              <ErrorBoundary name="gcc">
                                <GlobalCustomConfigurator itemId={itemId} />
                              </ErrorBoundary>
                            </Col>
                          )}
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Col>
            </ErrorBoundary>
          </Row>
          {
            mounted && fspipRecsPositionBelowABTest && (
              <ZoneCard>
                <PipSemWrapper
                  schemaName="pipsem"
                  anchorId={itemId}
                  errorBoundary
                  hideATC
                  withReferrer={viewReferer}
                />
              </ZoneCard>
            )
          }
          {!displayConfigurator && (
            <PMVAndFBT
              itemId={itemId}
              productData={productData}
              isPackage={isPackage}
              referer={referer}
            />
          )}
          {isBathCategory(productData) && fsRecsPipCollection && shouldDisplayPipCollections(productData) && (
            <Row>
              <DynamicRecsWrapper
                schemaName="pipcollection"
                anchorId={itemId}
                requestKey={RECS_REQUEST_KEY}
                isCollection
                isCategoryTab
                showColUpLevel
                errorBoundary
                maxResults="18"
                hideFavorites={false}
                hideSwatches={false}
                hydrator={{
                  className: 'grid',
                  tag: ZoneCard,
                  id: 'recs_pipcollection',
                  delay: 1500,
                  scrollBuffer: HYDRATION_STANDARD_SCROLL_BUFFER
                }}
              />
            </Row>
          )}
          <ErrorBoundary name="collapsible-sections">
            <DynamicComponent pageType="pip">
              <Hydrator
                id="collapsible-sections-accordion"
                className="grid"
                placeholder={(
                  <>
                    <div className="sui-flex sui-flex-col sui-w-full sui-gap-2">
                      <div
                        id="product-section-overview"
                        className="accordion-placeholder"
                        ref={productOverviewRef}
                      >
                        <Skeleton grow>
                          <SkeletonContent disablePadding>
                            <SkeletonLine
                              numberOfLines={2}
                              fullWidth
                            />
                          </SkeletonContent>
                        </Skeleton>
                      </div>
                      <div id="product-section-key-feat" className="accordion-placeholder">
                        <Skeleton grow>
                          <SkeletonContent disablePadding>
                            <SkeletonLine
                              numberOfLines={2}
                              fullWidth
                            />
                          </SkeletonContent>
                        </Skeleton>
                      </div>
                      <div id="product-section-qa" className="accordion-placeholder">
                        <Skeleton grow>
                          <SkeletonContent disablePadding>
                            <SkeletonLine
                              numberOfLines={2}
                              fullWidth
                            />
                          </SkeletonContent>
                        </Skeleton>
                      </div>
                      <div id="product-section-rr" className="accordion-placeholder">
                        <Skeleton grow>
                          <SkeletonContent disablePadding>
                            <SkeletonLine
                              numberOfLines={2}
                              fullWidth
                            />
                          </SkeletonContent>
                        </Skeleton>
                      </div>
                    </div>
                  </>
                )}
              >
                <div className="sui-flex sui-flex-col sui-w-full">
                  <div
                    id="product-section-overview"
                    className="product-section-overview sui-w-full"
                    ref={productOverviewRef}
                  >
                    <Accordion
                      key="product-details-accordion"
                      expanded={expanded['#product-section-overview'] || thdSeoBotDetected}
                      onChange={() => handleAccordionChange('#product-section-overview')}
                    >
                      <AccordionHeader>
                        <ProductOverviewAccordionTitle />
                      </AccordionHeader>
                      <AccordionBody>
                        <DynamicComponent pageType="pip">
                          <Hydrator
                            id="product-section-product-overview"
                            className="grid"
                            waitFor={ProductOverviewAccordionBodyLoad}
                            placeholder={(
                              <Placeholder
                                width="100%"
                                height="2000px"
                                color="#F5F5F5"
                              />
                            )}
                          >
                            <ProductOverviewAccordionBody
                              itemId={itemId}
                              showReturnMessage
                              revJet
                              layoutGroup={{
                                tag: Row,
                              }}
                              qctx={qctx}
                            />
                          </Hydrator>
                        </DynamicComponent>
                      </AccordionBody>
                    </Accordion>
                  </div>
                  <div
                    id="product-section-key-feat"
                    className="product-section-key-feat"
                    ref={specificationsRef}
                  >
                    <Accordion
                      key="specifications-accordion"
                      expanded={expanded['#product-section-key-feat'] || thdSeoBotDetected}
                      onChange={() => handleAccordionChange('#product-section-key-feat')}
                    >
                      <AccordionHeader>
                        <div className="sui-flex-col">
                          <SpecificationsAccordionTitle itemId={itemId} />
                        </div>
                      </AccordionHeader>
                      <AccordionBody>
                        <DynamicComponent pageType="pip">
                          <Hydrator
                            id="product-section-specifications"
                            className="grid"
                            waitFor={SpecificationsAccordionBodyLoad}
                            placeholder={(
                              <Placeholder
                                width="100%"
                                height="700px"
                                color="#F5F5F5"
                              />
                            )}
                          >
                            <SpecificationsAccordionBody
                              itemId={itemId}
                              layoutGroup={{
                                tag: Row,
                              }}
                              qctx={qctx}
                              oneColumn
                              isWhiteSpecTitle
                            />
                          </Hydrator>
                        </DynamicComponent>
                      </AccordionBody>
                    </Accordion>
                  </div>
                  <div
                    id="product-section-qa"
                    className="product-section-qa"
                    ref={questionsRef}
                  >
                    <Accordion
                      key="questions-answers-accordion"
                      expanded={expanded['#product-section-qa'] || thdSeoBotDetected}
                      onChange={() => handleAccordionChange('#product-section-qa')}
                    >
                      <AccordionHeader>
                        <QuestionsAndAnswersAccordionTitle
                          noSpacing
                          itemId={itemId}
                          layoutGroup={{
                            tag: Row,
                          }}
                          hydrator={{
                            className: 'grid'
                          }}
                          dynamic={{
                            pageType: 'pip'
                          }}
                        />
                      </AccordionHeader>
                      <AccordionBody>
                        <DynamicComponent pageType="pip">
                          <Hydrator
                            id="product-section-questions-answers"
                            className="grid"
                            waitFor={QuestionsAndAnswersAccordionBodyLoad}
                            placeholder={(
                              <Placeholder
                                width="100%"
                                height="700px"
                                color="#F5F5F5"
                              />
                            )}
                          >
                            <QuestionsAndAnswersAccordionBody
                              itemId={itemId}
                              seoPageNumber={1}
                              layoutGroup={{
                                tag: Row,
                              }}
                              qctx={qctx}
                            />
                          </Hydrator>
                        </DynamicComponent>
                      </AccordionBody>
                    </Accordion>
                  </div>
                  {!isHDPPSku
                    && (
                      <div
                        id="product-section-rr"
                        className="product-section-rr"
                        ref={ratingsRef}
                      >
                        <Accordion
                          key="ratings-reviews-accordion"
                          expanded={expanded['#product-section-rr'] || thdSeoBotDetected}
                          onChange={() => handleAccordionChange('#product-section-rr')}
                        >
                          <AccordionHeader>
                            <RatingsAndReviewsAccordionTitle
                              noSpacing
                              itemId={itemId}
                              layoutGroup={{
                                tag: Row,
                              }}
                              hydrator={{
                                className: 'grid'
                              }}
                              dynamic={{
                                pageType: 'pip'
                              }}
                            />
                          </AccordionHeader>
                          <AccordionBody>
                            <DynamicComponent pageType="pip">
                              <Hydrator
                                id="product-section-ratings-reviews"
                                className="grid"
                                waitFor={RatingsAndReviewsAccordionBodyLoad}
                                placeholder={(
                                  <Placeholder
                                    width="100%"
                                    height="2000px"
                                    color="#F5F5F5"
                                  />
                                )}
                              >
                                <RatingsAndReviewsAccordionBody
                                  itemId={itemId}
                                  qctx={qctx}
                                  prosAndCons
                                />
                              </Hydrator>
                            </DynamicComponent>
                          </AccordionBody>
                        </Accordion>
                      </div>
                    )}
                </div>
              </Hydrator>
            </DynamicComponent>
          </ErrorBoundary>
          {magicApronQABotEnabled && (
            <ErrorBoundary name="magic-apron-qa-bot">
              <DynamicComponent pageType="pip">
                <Hydrator
                  id="product-section-magic-apron-qa-bot"
                  className="grid"
                  scrollBuffer={HYDRATION_STANDARD_SCROLL_BUFFER}
                  waitFor={MagicApronBotPromptLoad}
                  placeholder={<Placeholder width="100%" height="276px" color="#F5F5F5" />}
                >
                  <MagicApronBotPrompt qctx={qctx} itemId={itemId} />
                </Hydrator>
              </DynamicComponent>
            </ErrorBoundary>
          )}
          <ErrorBoundary name="bath-renovation-banner">
            <DynamicComponent pageType="pip">
              <Hydrator
                id="product-section-bath-renovation-banner"
                className="grid"
                scrollBuffer={HYDRATION_STANDARD_SCROLL_BUFFER}
                placeholder={(
                  <Placeholder
                    width="100%"
                    height="150px"
                    color="#F5F5F5"
                  />
                )}
              >
                <ZoneCard className="zone-card">
                  <BathRenovation itemId={itemId} />
                </ZoneCard>
              </Hydrator>
            </DynamicComponent>
          </ErrorBoundary>
          {shouldDisplayPipInStock(productData, referer) && (
            <Row>
              <DynamicRecsWrapper
                schemaName="pipinstock"
                anchorId={itemId}
                errorBoundary
                requestKey={RECS_REQUEST_KEY}
                hideATC
                hydrator={{
                  className: 'grid',
                  tag: ZoneCard,
                  id: 'pipinstock',
                  scrollBuffer: 300
                }}
              />
            </Row>
          )}
          <SponsoredPipBanner
            browserId={thdCustomer?.mcvisID || ''}
            pageContext={{
              label: 'pip',
              data: {
                itemId,
                treatment: 'A'
              },
            }}
            layoutGroup={{
              name: 'pip-sponsored-banner-A'
            }}
            dynamic={{
              pageType: 'pip'
            }}
            hydrator={{
              id: 'hydrated-sponsored-pip-banner-A'
            }}
          />
          <EducationalSummary
            itemId={itemId}
            qctx={qctx}
            layoutGroup={{
              tag: Row,
            }}
            dynamic={{
              pageType: 'pip'
            }}
            hydrator={{
              className: 'grid'
            }}
          />
          {shouldDisplayBatterySystems(productData) ? (
            <Row>
              <DynamicRecsWrapper
                schemaName="battery_collection"
                anchorId={itemId}
                requestKey={RECS_REQUEST_KEY}
                isCollection
                isCategoryTab
                isBatteryCollection
                maxResults="18"
                hideFavorites={false}
                hydrator={{
                  className: 'grid',
                  tag: ZoneCard,
                  id: 'battery_collection',
                  delay: 1500,
                  scrollBuffer: HYDRATION_STANDARD_SCROLL_BUFFER
                }}
              />
            </Row>
          ) : !isBathCategory(productData) && fsRecsPipCollection && shouldDisplayPipCollections(productData) && (
            <Row>
              <DynamicRecsWrapper
                schemaName="pipcollection"
                anchorId={itemId}
                requestKey={RECS_REQUEST_KEY}
                isCollection
                isCategoryTab
                showColUpLevel
                errorBoundary
                maxResults="18"
                hideFavorites={false}
                hideSwatches={false}
                hydrator={{
                  className: 'grid',
                  tag: ZoneCard,
                  id: 'recs_pipcollection',
                  delay: 1500,
                  scrollBuffer: HYDRATION_STANDARD_SCROLL_BUFFER
                }}
              />
            </Row>
          )}
          {shouldDisplayIrgcollections(productData) && (
            <Row>
              <DynamicRecsWrapper
                schemaName="brand_based_collection"
                anchorId={itemId}
                requestKey={RECS_REQUEST_KEY}
                isIRG
                isCategoryTab
                errorBoundary
                maxResults="18"
                hideFavorites={false}
                hydrator={{
                  className: 'grid',
                  tag: ZoneCard,
                  id: 'recs_brand_based_collection',
                  delay: 1500,
                  scrollBuffer: HYDRATION_STANDARD_SCROLL_BUFFER
                }}
              />
            </Row>
          )}
          {shouldDisplayIrgaccessories(productData) && (
            <Row>
              <DynamicRecsWrapper
                schemaName="irgaccessories"
                anchorId={itemId}
                requestKey={RECS_REQUEST_KEY}
                isIRG
                errorBoundary
                maxResults="18"
                hideFavorites={false}
                hydrator={{
                  className: 'grid',
                  tag: ZoneCard,
                  id: 'recs_irgaccessories',
                  delay: 1500,
                  scrollBuffer: HYDRATION_STANDARD_SCROLL_BUFFER
                }}
              />
            </Row>
          )}
          {
            !hideSponsoredCarousel
            && (
              <ErrorBoundary name="sponsored-carousel-nucleus">
                <SponsoredCarousel
                  breadCrumbs={productData?.product?.taxonomy?.breadCrumbs}
                  pageContext={{
                    schema: 'pip_sponsored',
                    data: { itemId },
                    isPip: true
                  }}
                  showSponsoredCarousel
                  dynamic={{
                    pageType: 'pip'
                  }}
                  hydrator={{
                    className: 'grid',
                    tag: ZoneCard,
                    scrollBuffer: HYDRATION_STANDARD_SCROLL_BUFFER
                  }}
                />
              </ErrorBoundary>
            )
          }
          <Row>
            <FeatureBasedRecommendationsImporter
              hideOnMobile
              itemId={itemId}
              qctx={qctx}
              errorBoundary
              hydrator={{
                tag: ZoneCard,
                className: 'grid'
              }}
              dynamic={{
                pageType: 'pip'
              }}
            />
          </Row>
          {(fspipRecsPositionDefaultABTest || (!pipRecsABTest && shouldDisplayPipAlternativeRecs(productData, referer)))
          && (
            <Row>
              <DynamicRecsWrapper
                schemaName="pip_alternatives"
                anchorId={itemId}
                errorBoundary
                requestKey={RECS_REQUEST_KEY}
                hydrator={{
                  className: 'grid',
                  tag: ZoneCard,
                  id: fspipRecsPositionDefaultABTest ? 'RecsDefault' : 'pip_alternatives',
                  scrollBuffer: HYDRATION_STANDARD_SCROLL_BUFFER
                }}
                impressionData={{
                  component: 'DynamicRecs',
                  type: 'product'
                }}
              />
            </Row>
          )}
          {shouldDisplayVisuallySimilar(productData) && (
            <Row>
              <DynamicRecsWrapper
                schemaName="visuallysimilar_1_0"
                anchorImage={productData?.product?.media?.image?.url}
                anchorId={itemId}
                errorBoundary
                checkMinimumProducts
                minimumProductCount={4}
                requestKey={RECS_REQUEST_KEY}
                hydrator={{
                  className: 'grid',
                  tag: ZoneCard,
                  id: 'visuallysimilar_1_0',
                  scrollBuffer: HYDRATION_STANDARD_SCROLL_BUFFER
                }}
              />
            </Row>
          )}
          <Row>
            <QueryProvider
              defaultVariables={defaultVariables?.current}
              persist
              cacheKey="rv-pip-recs"
            >
              <RecentlyViewedWrapper
                schemaName="rv_gm_pip_rr"
                errorBoundary
                hydrator={{
                  className: 'grid',
                  tag: ZoneCard,
                  id: 'rv_gm_pip_rr',
                  delay: 2000,
                  scrollBuffer: HYDRATION_STANDARD_SCROLL_BUFFER
                }}
              />
            </QueryProvider>
          </Row>
          <RelatedSearch
            itemId={itemId}
            layoutGroup={{
              tag: ChildMap
            }}
            dynamic={{
              pageType: 'pip'
            }}
            hydrator={{
              className: 'col__12-12'
            }}
          />
          <PopularVariations
            productId={itemId}
            dynamic={{
              pageType: 'pip'
            }}
            hydrator={{
              className: 'col__12-12'
            }}
          />
          <EmtLinks
            path={pathname}
            layoutGroup={{
              tag: Row
            }}
            dynamic={{
              pageType: 'pip',
            }}
            hydrator={{
              className: 'col__12-12 sui-mt-4'
            }}
          />
        </Row>
      </ErrorBoundary>
    </>
  );
};

Product.displayName = 'ProductPage';

Product.themeProps = {
  hideConsumerCardMessaging: bool,
  hideReturnsMessaging: bool,
  hideSponsoredCarousel: bool,
  hideShareBeltShareButton: bool,
  hideAddToList: bool
};

Product.defaultThemeProps = {
  hideConsumerCardMessaging: false,
  hideReturnsMessaging: false,
  hideSponsoredCarousel: false,
  hideShareBeltShareButton: false,
  hideAddToList: false
};

Product.propTypes = {
  cookieUtils: shapeProp({ object })
};

Product.defaultProps = {
  cookieUtils: {}
};

Product.dataModel = extend(
  {
    product: params({ itemId: string().isRequired() }).shape({
      fulfillment: params({ storeId: string(), zipCode: string() }).shape({
        backordered: bool()
      }),
      info: shape({
        dotComColorEligible: bool(),
        globalCustomConfigurator: shape({
          customPosition: string()
        })
      }),
      identifiers: shape({
        skuClassification: string()
      }),
    })
  },
  {
    clientOnlyProduct: params({ itemId: string().isRequired }).shape({
      itemId: string(),
      dataSources: string(),
      info: shape({
        gccExperienceOmsId: string(),
        recommendationFlags: shape({
          visualNavigation: bool(),
          pipCollections: bool(),
          packages: bool(),
          ACC: bool(),
          collections: bool(),
          frequentlyBoughtTogether: bool(),
          bundles: bool(),
          batItems: bool()
        }),
        dotComColorEligible: bool(),
        globalCustomConfigurator: shape({
          customPosition: string()
        })
      }),
      identifiers: shape({
        roomVOEnabled: bool(),
        productType: string(),
        skuClassification: string()
      }),
      availabilityType: shape({
        discontinued: bool(),
        type: string()
      }),
      fulfillment: params({ storeId: string() }).shape({
        backordered: bool(),
        fulfillmentOptions: arrayOf(shape({
          type: string(),
          fulfillable: bool(),
          services: arrayOf(shape({
            type: string(),
            locations: arrayOf(shape({
              isAnchor: bool(),
              inventory: shape({
                isLimitedQuantity: bool(),
                isOutOfStock: bool()
              })
            }))
          }))
        })),
      }),
    })
  },
  {
    clientOnlyProduct: params({ itemId: string().isRequired }).shape({
      seoDescription: string()
    })
  },
  {
    products: params({ itemIds: arrayOf(string().isRequired()).isRequired() }).arrayOf({
      itemId: string(),
      fulfillment: params({ storeId: string() }).shape({
        anchorStoreStatusType: string(),
        backordered: bool(),
        backorderedShipDate: string(),
        bossExcludedShipStates: string(),
        bodfsAssemblyEligible: bool(),
        seasonStatusEligible: bool(),
        excludedShipStates: string(),
        fulfillmentOptions: shape({
          fulfillable: bool(),
          messages: string(),
          services: shape({
            type: string(),
            isDefault: bool(),
            deliveryCharge: string(),
            deliveryDates: shape({
              endDate: string(),
              startDate: string(),
            }),
            deliveryTimeline: string(),
            freeDeliveryThreshold: string(),
            totalCharge: string(),
            locations: shape({
              type: string(),
              isAnchor: bool(),
              locationId: string(),
              storeName: string(),
              storePhone: string(),
              state: string(),
              distance: string(),
              inventory: shape({
                quantity: string(),
                isInStock: bool(),
                isOutOfStock: bool(),
                isLimitedQuantity: bool(),
                isUnavailable: bool(),
                maxAllowedBopisQty: string(),
                minAllowedBopisQty: string(),
              }),
            })
          }),
          type: string()
        }),
      }),
    })
  },
  AisleBay,
  AugmentedReality,
  BathRenovation,
  Breadcrumbs,
  Buybox,
  CalcLink,
  ConsumerCardMessaging,
  ProductPageEventCountdownTimer,
  DisclaimerMessage,
  Discontinued,
  DynamicRecsWrapper,
  PipSemWrapper,
  EducationalSummary,
  EmtLinks,
  FrequentlyBoughtTogether,
  GlobalCustomConfigurator,
  MediaGalleryWithHotspotsDesktop,
  OrderSample,
  PackagesMiniVisual,
  PIPCalculator,
  WallAreaCalculator,
  PIPRedirector,
  Price,
  ProductDetails,
  ProductConfigurator,
  ProductInfoBar,
  ProductMetadata,
  Promotion,
  PromotionProductsAnchor,
  RecentlyViewedWrapper,
  RelatedSearch,
  PopularVariations,
  SalientPoints,
  Sharebelt,
  StickyNav,
  SuperSku,
  ThdRecsFbt,
  FeatureBasedRecommendationsImporter,
  ProductOverviewAccordionBodyModel,
  QuestionsAndAnswersAccordionBodyModel,
  RatingsAndReviewsAccordionBodyModel,
  SpecificationsAccordionBodyModel,
  PriceClearanceDataModel
);
